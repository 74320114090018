@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;1,200;1,300&family=Noto+Sans+Math&family=Roboto:wght@300;400&display=swap');

.popup {
  position:absolute;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  max-width: 50em;
  padding: 0.5em;
  background: #002b36;
  color: #93a1a1;
  font-family: 'Source Code Pro', Menlo, Monaco, Consolas, monospace;
  font-size: 0.7em;
  border: 1px solid #93a1a1;
  box-shadow: 0px 0px 15px 3px rgba(80, 80, 80, 0.2);
  /*box-shadow: 5px 5px 0px 0px rgba(80, 80, 80, 0.5);*/
  text-align: left;
  border-radius: 3px;
}

@media only screen and (max-width: 900px) {
  /* For mobile phones: */
  .popup {
    left: 0;
    top: 0;
    width: 800;
  }
}