a {
  color: inherit;
}
a:hover {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.container {
  /*height: 100vh;*/
  /*min-height: 50vh;*/
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5vh;
  padding-bottom: 5vh;
}


@media only screen and (max-width: 900px) {
  /* For mobile phones: */
  .container {
    padding-top: 5px;
    padding-bottom: 5px;
  }
/*  .window {
    width: 800vw;
  }*/
}

.window {
  z-index: auto;
  max-width: 45em;
  /*min-width: 42em;*/
  width: 100%;
  text-align: left;
  /*border: 1px solid #93a1a1;*/
  border-radius: 10px;
  box-shadow: 0px 7px 30px 8px rgba(128, 128, 128, 0.5);
  
}

/*.title-bg-holder {
  pointer-events: none;
  z-index: 1;
  position: absolute;
  top: 2.6em;
  width: 42em;
  height: 50%;
}

.title-bg {
  position: sticky;
  top: 0;
  height: 1em;
  background-color: #D1CCC0;
}*/

.title {
  z-index: 2;
  /*position: sticky;*/
  top: -1px;
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  border-top: 1.5px solid #B2AEAA;
  border-left: 1px solid #B2AEAA;
  border-right: 1px solid #B2AEAA;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 2.5em;
  align-items: center;
  /*padding: 0.3em;*/
  padding-left: 0.5em;
  padding-right: 0.5em;
  background: rgb(69,64,61);
  background: linear-gradient(0deg, rgba(69,64,61,1) 0%, rgba(83,78,74,1) 100%);
  color: #eee8d5;
  font-size: 1rem;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
