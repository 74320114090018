@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;1,200;1,300&family=Roboto:wght@300;400&display=swap');

.document {
  /*z-index: 0;*/
  border-bottom: 1.5px solid #A49F93;
  border-left: 1px solid #A49F93;
  border-right: 1px solid #A49F93;
  /*padding: 0.5em;*/
  background: #F3F3F4;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-bottom: 0.5em;
}

.page {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding:  1em;
  /*padding-bottom: 1.5em;*/
  background: #FFFFFF;
  border-left: 1px solid #C6C6C6;
  border-right: 1px solid #C6C6C6;
  border-bottom: 1px solid #C6C6C6;
  box-shadow: 0px 1px 4px 0px rgba(128, 128, 128, 0.5);
  font-family: 'Montserrat', sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.3em;
}

.line {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.chapter {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /*font-style: italic;*/
  font-size: 1rem;
  text-transform: uppercase;
  /*color: #6c71c4;*/
  letter-spacing: 0.2em;
  padding-bottom: 0.5rem;
  margin-bottom: 0.2rem;
  width: 100%;
  border-bottom: 1px solid #C6C6C6;
}

.position {
  font-weight: 500;
}

.headline {
  font-weight: 500;
  /*margin-top: 0.4em;*/
  margin-bottom: 0.9em;
}

.footerDocument {
  font-family: 'Source Code Pro', Menlo, Monaco, monospace;
  padding-top: 0.7em;
  padding-left: 0.3em;
  color: #657b83;
  font-size: 0.7em;
}
.footerDocument a{
  color:#93a1a1;
}
.footerDocument a:hover{
  text-decoration: none;
}


@media only screen and (max-width: 900px) {
  /* For mobile phones: */
  .document {
    padding-left: 0px;
    padding-right: 0px;
  }
  .page {
    box-shadow: none;
  }
  .footerDocument {
    padding-left: 1.3em;
  }
}