@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

.terminal {
  border-bottom: 1.5px solid #93a1a1;
  border-left: 1px solid #93a1a1;
  border-right: 1px solid #93a1a1;
  padding: 0.5em;
  background: #002b36;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  font-family: 'Source Code Pro', Menlo, Monaco, Consolas, monospace;
}



.code {
  display: flex;
  flex-direction: column;
  /*gap: 0.5em;*/
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  /*color: #93a1a1;*/
  color: #586e75;
  font-size: 0.8em;
  font-family: inherit;
  /*white-space: pre-wrap; */
  /*word-wrap: break-word;*/
}
.code a{
    color:#93a1a1;
}

.keywordGreen {
  color: #859900;
}

.keywordBlue {
  color: #268bd2;
}

.keywordOrange {
  color: #b58900;
}

.keywordGrey {
  color: #93a1a1;
}

.keywordFill {
  background-color: #b58900;
  color: #FFFFFF;
}

.footerCode {
  padding-top: 0.5em;
  color: #657b83;
  font-size: 0.7em;
  font-family: inherit;
}
.footerCode a{
    color:#93a1a1;
}

/*.line {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}*/

.blinking { 
  position: relative;
  display: inline-block;
}

.blinking::after {
  content: "";
  position: absolute;
  top: 0.15em;
  right: -1em;
  display: inline-block;
  background-color: #657b83;
  vertical-align: top;
  width: 0.5em;
  height: 1em;
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  0% { opacity: 1.0; }
  60% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.loading { 
  position: relative;
  display: inline-block;
}

.loading::after {
  content: "";
  position: absolute;
  display: inline-block;
  vertical-align: top;
  /*width: 0.5em;*/
  /*height: 1em;*/
  animation: progress 3s step-end infinite;
}

@keyframes progress {
  0% { content: ""; }
  10% { content: "."; }
  20% { content: ".."; }
  30% { content: "..."; }
}
